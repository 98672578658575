export const FLOWABLE_STATUS = [
  {
    label: '处理中',
	type: 'primary',
    value: 1
  },
  {
    label: '已撤销',
	type: 'danger',
    value: 2
  },
  {
    label: '已驳回',
	type: 'danger',
    value: 3
  },
  {
    label: '已退回',
	type: 'danger',
    value: 4
  },
  {
    label: '已结束',
	type: 'success',
    value: 5
  },
  {
    label: '已挂起',
	type: 'info',
    value: 6
  }
]
export const AUDIT_STATUS = [
  {
    label: '待审批',
	type: 'primary',
    value: 1
  },
  {
    label: '已撤销',
	type: 'danger',
    value: 2
  },
  {
    label: '已驳回',
	type: 'danger',
    value: 3
  },
  {
    label: '已退回',
	type: 'danger',
    value: 4
  },
  {
    label: '已同意',
	type: 'success',
    value: 5
  },
  {
    label: '已挂起',
	type: 'info',
    value: 6
  }
]
export const NODE_STATUS = [
  {
    label: '未审批',
	type: 'info',
	icon: 'el-icon-timer',
    value: 0
  },
  {
    label: '待审批',
  	type: 'primary',
	icon: 'el-icon-loading',
    value: 1
  },
  {
    label: '已通过',
	type: 'success',
	icon: 'el-icon-success',
    value: 2
  },
  {
    label: '已驳回',
	type: 'danger',
	icon: 'el-icon-error',
    value: 3
  }
]
//根节点默认属性
export const ROOT_PROPS = {
	assignedUser: []
}

//审批节点默认属性
export const APPROVAL_PROPS = {
	assignedType: "ASSIGN_USER",
	mode: "ORDERLY",
	sign: false,
	nobody: {
		handler: "TO_PASS",
		assignedUser: []
	},
	assignedUser: [],
	selfSelect: {
		multiple: false
	},
	asyncTask: {
		async: false,
		interval: 0,
		unit: 'hour',
		expired: 1,
		handler: 'TO_REFUSE'
	}
}

//抄送节点默认属性
export const CC_PROPS = {
	assignedUser: [],
	selfSelect: {
		permission: false
	},
}

//条件节点默认属性
export const EXCLUSIVE_PROPS = {
	groups: [{
		conditions: [] //组内子条件
	}],
}

//触发器节点默认属性
export const TRIGGER_PROPS = {
	type: 'WEBHOOK',
	http: {
		method: 'GET', //请求方法 支持GET/POST
		url: '', //URL地址，可以直接带参数
		headers: [ //http header
			{
				name: '',
				isField: true,
				value: '' //支持表达式 ${xxx} xxx为表单字段名称
			}
		],
		contentType: 'FORM', //请求参数类型
		params: [ //请求参数
			{
				name: '',
				isField: true, //是表单字段还是自定义
				value: '' //支持表达式 ${xxx} xxx为表单字段名称
			}
		],
		retry: 1,
		handlerByScript: false,
		success: 'function handlerOk(res) {\n  return true;\n}',
		fail: 'function handlerFail(res) {\n  return true;\n}'
	},
	email: {
		subject: '',
		to: [],
		content: ''
	}
}

//延时节点默认属性
export const DELAY_PROPS = {
	type: "FIXED", //延时类型 FIXED:到达当前节点后延时固定时长 、AUTO:延时到 dateTime设置的时间
	time: 0, //延时时间
	unit: "M", //时间单位 D天 H小时 M分钟
	dateTime: "" //如果当天没有超过设置的此时间点，就延时到这个指定的时间，到了就直接跳过不延时
}

export default {
	FLOWABLE_STATUS,
	AUDIT_STATUS,
	NODE_STATUS,
	ROOT_PROPS,
	APPROVAL_PROPS,
	CC_PROPS,
	EXCLUSIVE_PROPS,
	TRIGGER_PROPS,
	DELAY_PROPS,
}