import Constant from '../../common/Constant.js'
export default {
	methods: {
		flowableStatus(status) {
			return Constant.FLOWABLE_STATUS?.find(_ => _.value == status)?.label
		},
		flowableType(status) {
			return Constant.FLOWABLE_STATUS?.find(_ => _.value == status)?.type
		},
		auditStatus(status) {
			return Constant.AUDIT_STATUS?.find(_ => _.value == status)?.label
		},
		auditType(status) {
			return Constant.AUDIT_STATUS?.find(_ => _.value == status)?.type
		}
	}
}