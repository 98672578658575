<template>
	<div class="container">
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>审批中心</el-breadcrumb-item>
			<el-breadcrumb-item>抄送我的</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="table">
			<el-row style="margin: 5px auto; float: right;">
				<el-button type="info" icon="el-icon-refresh" size="small" circle @click="listSelfAbout"></el-button>
			</el-row>
			<el-table :data="tableData" border stripe>
				<el-table-column type="index" label="#" />
				<el-table-column prop="templateName" label="申请单" />
				<el-table-column prop="unique" label="业务单号" />
				<el-table-column label="申请人">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user">
							{{ scope.row.applier.nickname }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="applyTime" label="申请时间" width="160" />
				<el-table-column prop="createTime" label="接收时间" width="160" />
				<el-table-column label="当前审批人">
					<template slot-scope="scope">
						<el-tag
							v-if="scope.row.auditors && scope.row.auditors.length > 0"
							size="mini"
							v-for="(auditor, index) in scope.row.auditors"
							:key="index"
							effect="dark"
							class="user"
						>
							{{ auditor?.nickname }}
						</el-tag>
						<span>--</span>
					</template>
				</el-table-column>
				<el-table-column label="流程状态">
					<template slot-scope="scope">
						<el-tag size="mini" effect="dark" class="user" :type="flowableType(scope.row.status)">
							{{ flowableStatus(scope.row.status) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="info"
							@click="handleView(scope.row)"
						>
							流程图
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				layout="prev, pager, next, total"
				:current-page.sync="pageNo"
				@current-change="listSelfAbout"
				:total="total"
			/>
		</div>
		<el-drawer
			title="流程图"
			:visible.sync="show"
			:modal-append-to-body="false"
			size="500px"
			direction="rtl"
			:modal="false"
			destroy-on-close
		>
			<div class="">
				<process-diagram-viewer />
			</div>
		</el-drawer>
	</div>
</template>
<script>
import ProcessDiagramViewer from '../../common/ProcessDiagramViewer'
import mixins from '../mixins/mixins'

export default {
	components: {
		ProcessDiagramViewer
	},
	mixins: [mixins],
	data() {
		return {
			show: false,
			tableData: [],
			total: 0,
			pageNo: 1,
			pageSize: 50
		}
	},
	mounted() {
		this.listSelfAbout()
	},
	methods: {
		async listSelfAbout() {
			const operator = sessionStorage.getItem('operator')
			const { total, list } = await this.$api.flowable.selfAbout({
				operator: operator,
				pageNo: this.pageNo,
				pageSize: this.pageSize
			})
			this.total = total
			this.tableData = list
		},
		async handleView(row) {
			const {
				template,
				finishedNodeIds,
				runningNodeIds,
				waitingNodeIds
			} = await this.$api.flowable.instanceInfo({
				processInstanceId: row.processInstanceId,
				taskId: row.taskId
			})
			template.process = JSON.parse(template.process)
			this.$store.state.flowable.template = template
			this.$store.state.flowable.finishedNodeIds = finishedNodeIds
			this.$store.state.flowable.runningNodeIds = runningNodeIds
			this.$store.state.flowable.waitingNodeIds = waitingNodeIds
			this.showFlowable = true
		}
	}
}
</script>
<style lang="less" scoped>
.container {
	.breadcrumb {
		margin-bottom: 10px;
	}
	.table {
		background-color: #fff;
		padding: 10px;
		border-radius: 5px;
	}
	.user {
		margin-right: 5px;
	}
}
</style>