<template>
	<div>
		<el-main>
			<div class="scale">
				<el-button
					icon="el-icon-plus"
					size="small"
					@click="scale += 10"
					:disabled="scale >= 150"
					circle
				></el-button>
				<span>{{ scale }}%</span>
				<el-button
					icon="el-icon-minus"
					size="small"
					@click="scale -= 10"
					:disabled="scale <= 40"
					circle
				></el-button>
			</div>
			<div
				class="design"
				:style="'transform: scale(' + scale / 100 + ');'"
			>
				<process-viewer ref="process" @selectedNode="nodeSelected" />
			</div>
		</el-main>
	</div>
</template>

<script>
import ProcessViewer from './ProcessViewer.vue'

export default {
	name: 'ProcessDiagramViewer',
	components: { ProcessViewer },
	props: {
		mode: {
			type: String,
			default: 'viewer'
		}
	},
	data() {
		return {
			scale: 100,
			selected: {}
		}
	},
	created() {
		this.$store.state.flowable.mode = this.mode
	},
	beforeDestroy() {
		this.$store.state.flowable.mode = 'design'
	},
	methods: {
		nodeSelected(nodeInfo) {
			this.$emit('selectedNode', nodeInfo);
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
.design {
	margin-top: 100px;
	display: flex;
	transform-origin: 50% 0px 0px;
}

.scale {
	z-index: 999;
	position: fixed;
	top: 80px;
	right: 40px;

	span {
		margin: 0 10px;
		font-size: 15px;
		color: #7a7a7a;
		width: 50px;
	}
}

.node-config-content {
	padding: 0 20px 20px;
}

/deep/ .el-drawer__body {
	overflow-y: auto;
}
</style>
