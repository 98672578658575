<script>
//导入所有节点组件
import Approval from './nodes/ApprovalNode.vue';
import Cc from './nodes/CcNode.vue';
import Parallel from './nodes/ParallelNode.vue';
import Exclusive from './nodes/ExclusiveNode.vue';
import Trigger from './nodes/TriggerNode.vue';
import Delay from './nodes/DelayNode.vue';
import Empty from './nodes/EmptyNode.vue';
import Root from './nodes/RootNode.vue';
import Node from './nodes/Node.vue';

export default {
	name: 'ProcessViewer',
	components: { Node, Root, Approval, Cc, Trigger, Parallel, Exclusive, Delay, Empty },
	data() {
		return {
			valid: true
		};
	},
	computed: {
		nodeMap() {
			return this.$store.state.flowable.nodeMap;
		},
		nodeInfo() {
			return this.$store.state.flowable.template.process;
		}
	},
	render(h, ctx) {
		this.nodeMap.clear();
		let doms = this.getDomTree(h, this.nodeInfo);
		//插入末端节点
		doms.push(h('div', { style: { 'text-align': 'center' } }, [h('div', { class: { 'process-end': true }, domProps: { innerHTML: '流程结束' } })]));
		return h('div', { class: { _root: true }, ref: '_root' }, doms);
	},
	methods: {
		getDomTree(h, node) {
			this.toMapping(node);
			if (this.isPrimaryNode(node)) {
				//普通业务节点
				let childDoms = this.getDomTree(h, node.child);
				let headerBgColor = '#ff943e';
				if (this.$store.state.flowable.runningNodeIds.includes(node.id)) {
					headerBgColor = '#1e90ff';
				} else if (this.$store.state.flowable.finishedNodeIds.includes(node.id)) {
					headerBgColor = '#20b2aa';
				} else if (this.$store.state.flowable.waitingNodeIds.includes(node.id)) {
					headerBgColor = '#909399';
				}
				node.props.headerBgColor = headerBgColor;
				this.decodeAppendDom(h, node, childDoms);
				return [h('div', { class: { 'primary-node': true } }, childDoms)];
			} else if (this.isBranchNode(node)) {
				let index = 0;
				//遍历分支节点，包含并行及条件节点
				let branchItems = node.branches.map((branchNode) => {
					//处理每个分支内子节点
					this.toMapping(branchNode);
					let childDoms = this.getDomTree(h, branchNode.child);
					this.decodeAppendDom(h, branchNode, childDoms, { level: index + 1, size: node.branches.length });
					//插入4条横线，遮挡掉条件节点左右半边线条
					this.insertCoverLine(h, index, childDoms, node.branches);
					//遍历子分支尾部分支
					index++;
					return h('div', { class: { 'branch-node-item': true } }, childDoms);
				});

				let branchDoms = [h('div', { class: { 'branch-node': true } }, branchItems)];
				//继续遍历分支后的节点
				let afterChildDoms = this.getDomTree(h, node.child);
				return [h('div', {}, [branchDoms, afterChildDoms])];
			} else if (this.isEmptyNode(node)) {
				//空节点，存在于分支尾部
				let childDoms = this.getDomTree(h, node.child);
				this.decodeAppendDom(h, node, childDoms);
				return [h('div', { class: { 'empty-node': true } }, childDoms)];
			} else {
				//遍历到了末端，无子节点
				return [];
			}
		},
		//解码渲染的时候插入dom到同级
		decodeAppendDom(h, node, dom, props = {}) {
			props.nodeInfo = node;
			dom.unshift(
				h(
					node.type.toLowerCase(),
					{
						props: props,
						ref: node.id,
						key: node.id,
						//定义事件，选中节点
						on: {
							selected: () => this.selectNode(props)
						}
					},
					[]
				)
			);
		},
		//id映射到map，用来向上遍历
		toMapping(node) {
			if (node && node.id) {
				this.nodeMap.set(node.id, node);
			}
		},
		insertCoverLine(h, index, doms, branches) {
			if (index === 0) {
				//最左侧分支
				doms.unshift(h('div', { class: { 'line-top-left': true } }, []));
				doms.unshift(h('div', { class: { 'line-bot-left': true } }, []));
			} else if (index === branches.length - 1) {
				//最右侧分支
				doms.unshift(h('div', { class: { 'line-top-right': true } }, []));
				doms.unshift(h('div', { class: { 'line-bot-right': true } }, []));
			}
		},
		//判断是否为主要业务节点
		isPrimaryNode(node) {
			return node && (node.type === 'ROOT' || node.type === 'APPROVAL' || node.type === 'CC' || node.type === 'DELAY' || node.type === 'TRIGGER');
		},
		isBranchNode(node) {
			return node && (node.type === 'EXCLUSIVES' || node.type === 'PARALLELS');
		},
		isEmptyNode(node) {
			return node && node.type === 'EMPTY';
		},
		//选中一个节点
		selectNode(props) {
			this.$emit('selectedNode', props.nodeInfo);
		},
	},
	watch: {}
};
</script>

<style lang="less" scoped>
._root {
	margin: 0 auto;
}
.process-end {
	width: 60px;
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 15px;
	padding: 5px 10px;
	font-size: small;
	color: #747474;
	background-color: #f2f2f2;
	box-shadow: 0 0 10px 0 #bcbcbc;
}
.primary-node {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.branch-node {
	display: flex;
	justify-content: center;
	/*border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;*/
}
.branch-node-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: 2px solid #cccccc;
	border-bottom: 2px solid #cccccc;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: calc(50% - 1px);
		margin: auto;
		width: 2px;
		height: 100%;
		background-color: #cacaca;
	}
	.line-top-left,
	.line-top-right,
	.line-bot-left,
	.line-bot-right {
		position: absolute;
		width: 50%;
		height: 4px;
		background-color: #fff;
	}
	.line-top-left {
		top: -2px;
		left: -1px;
	}
	.line-top-right {
		top: -2px;
		right: -1px;
	}
	.line-bot-left {
		bottom: -2px;
		left: -1px;
	}
	.line-bot-right {
		bottom: -2px;
		right: -1px;
	}
}
.add-branch-btn {
	position: absolute;
	width: 80px;
	.add-branch-btn-el {
		z-index: 999;
		position: absolute;
		top: -15px;
	}
}

.empty-node {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
</style>
